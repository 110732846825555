<template>
  <el-popover
      placement="left-start"
      :width="200"
      trigger="hover"
  >
    <template #reference>
      <div class="warp">
        <img src="../../../assets/wechat.png" width="20" height="20" alt="">
        <div style="padding: 0 5px">联系平台微信</div>
      </div>
    </template>
    <img src="../../../assets/contactWechat.jpg" width="200" height="200" alt="">
  </el-popover>
</template>

<script>
/**
 *   @Author : 王雨波
 *   @Date : 2022/1/5
 *   @Version : 1.0.0
 *   @Last Modified by : 王雨波
 *   @Last Modified time : 2022/1/5
 *   @desc 联系平台微信  组件
 **/

export default {
  name: "ContactWechat"
}
</script>


<style scoped lang="less">

  .warp {
    display: flex;
    align-items: center;


  }

</style>
