<template>
  <div class="iu">
    <el-form
      :inline="true"
      :model="formInline"
      label-width="100px"
      :rules="rules"
      ref="form"
    >
      <div class="iu-title">客户信息</div>
      <el-row v-if="userInfo.userType === '0'">
        <el-col :span="24">
          <el-form-item label="询价人" prop="inquiryCustomerId">
            <el-select
              v-model="formInline.inquiryCustomerId"
              filterable
              placeholder="请选择询价人"
              @change="getinquiryCustomerId"
            >
              <el-option
                :label="item.realName + '/' + item.enterpriseName"
                :value="item.id"
                :key="item.id"
                v-for="item in reals"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="固定电话" prop="fixedPhone">
            <el-input
              v-model="formInline.fixedPhone"
              placeholder="请输入固定电话"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="币种选择" prop="priceUnit ">
            <el-select
              filterable
              v-model="formInline.priceUnit"
              placeholder="请选择货币类型"
            >
              <el-option
                v-for="(item, index) in moneys"
                :label="item.title"
                :key="index"
                :value="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="付款方式" prop="paymentType">
            <el-select
              filterable
              v-model="formInline.paymentType"
              placeholder="请选择支付方式"
            >
              <el-option
                v-for="(item, index) in paymentTypes"
                :key="index"
                :label="item.title"
                :value="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label=" ">
            <el-radio-group v-model="formInline.projectType">
              <el-radio
                :label="item.type"
                v-for="item in projectType"
                :key="item.type"
              >
                {{ item.title }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="radio-tips">
            注：金额为100万以下的为一般项目，金额100万以上的为特殊项目，请正确选择，方便获取报价信息
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="formInline.projectType === 1 || formInline.projectType === 2"
      >
        <el-col :span="8">
          <el-form-item label="项目名称" prop="projectName">
            <el-input
              v-model="formInline.projectName"
              placeholder="请输入项目名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所在区域" prop="projectProvince">
            <el-cascader
              v-model="formInline.projectProvince"
              :options="areaInfo"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="详细地址" prop="projectAddr">
            <el-input
              v-model="formInline.projectAddr"
              placeholder="请输入项目地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="formInline.projectType === 1 || formInline.projectType === 2"
      >
        <el-col :span="8">
          <el-form-item label="项目状态" prop="projectState">
            <el-select
              filterable
              v-model="formInline.projectState"
              placeholder="请选择项目状态"
            >
              <el-option
                :label="item.title"
                :value="item.type"
                v-for="item in projectState"
                :key="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="厂家联系人">
            <el-input
              v-model="formInline.contactName"
              placeholder="请输入厂家联系人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="厂家联系电话">
            <el-input
              v-model="formInline.contactPhone"
              placeholder="请输入厂家联系电话"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="formInline.projectType === 1 || formInline.projectType === 2"
      >
        <el-col :span="24">
          <el-form-item label="项目图纸" prop="projectFileIds">
            <uploadFile
              :list="projectList"
              v-model="formInline.projectFileIds"
            ></uploadFile>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="收货人" prop="shipId">
            <el-select
              filterable
              v-model="formInline.shipId"
              placeholder="请选择收货人"
            >
              <el-option
                :label="item.consigneeName"
                :value="item.id"
                v-for="item in addressList"
                :key="item.id"
              ></el-option>
            </el-select>
            <el-button
              @click="addConsignee"
              style="margin-left: 10px"
              type="primary"
              size="mini"
              >添加</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="consigneePhone">
            <el-input
              v-model="formInline.consigneePhone"
              placeholder="请输入联系电话"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所在区域" prop="areas">
            <el-cascader
              v-model="formInline.areas"
              :options="areaInfo"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
              disabled
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="详细地址" prop="address">
            <el-input
              v-model="formInline.address"
              placeholder="请输入详细地址"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input
              @change="changeRemarks"
              :rows="2"
              type="textarea"
              v-model="formInline.remarks"
              placeholder=""
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
  <add-address v-model="addressShow" @success="changeAddress" />
</template>

<script>
import area from "@/common/json/area.json";
import { handleTreeData, runNumber } from "@/common/js/common.js";
import uploadFile from "@/components/publicComponent/uploadFile.vue"; //上传文件
import addAddress from "../../myMessageModule/components/dialog/addAddress.vue"; //添加地址
import { mapActions, mapState } from "vuex";
import { ElMessage } from "element-plus";

export default {
  props: ["isPlatform"],
  data() {
    return {
      addressShow: false, //添加收货人弹框
      areaInfo: handleTreeData(area, "name", "name"),
      cityInfo: handleTreeData(area, "name", "name", 2),
      addressList: [], //收货地址列表
      reals: [], //询价人列表
      formInline: {
        //需要提交的数据
        inquiryCustomerId: "", //询价人
        fixedPhone: "", //固定电话
        priceUnit: "CNY", //货币类型
        paymentType: "PAY_ON_001", //支付方式
        projectType: 0, //项目类型
        shipId: "", //收货人id
        sellerUserIds: [], //询价设置
        isAgent: "", //是否代理
        // shelfLife: "1", //保证期
        sellerAddr: [["不限"]], //地点
        regDate: "", //注册时间
        areas: [], //所在区域
        consigneePhone: "", //联系电话
        projectFileIds: [], //上传文件的ids
        projectState: null, //项目状态
        projectProvince: [], //项目区域
        projectName: "", //项目名称
        projectAddr: "", //项目地址
        contactName: "", //厂家联系人
        contactPhone: "", //厂家联系电话
      },
      rules: {
        fixedPhone: [
          { required: false, message: "请输入固定电话", trigger: "blur" },
        ],
        priceUnit: [
          { required: false, message: "请选择货币类型", trigger: "change" },
        ],
        paymentType: [
          { required: false, message: "请选择支付方式", trigger: "change" },
        ],
        shipId: [{ required: false, message: "请选择收货人", trigger: "blur" }],
        areas: [
          { required: false, message: "请选择所在区域", trigger: "change" },
        ],
        address: [
          { required: false, message: "请输入详细地址", trigger: "blur" },
        ],
        consigneePhone: [
          { required: false, message: "请输入联系电话", trigger: "blur" },
        ],
        projectState: [
          { required: false, message: "请选择项目状态", trigger: "change" },
        ],
        projectName: [
          { required: false, message: "请输入项目名称", trigger: "blur" },
        ],
        projectProvince: [
          { required: false, message: "请选择项目区域", trigger: "change" },
        ],
        projectAddr: [
          { required: false, message: "请输入项目详细地址", trigger: "blur" },
        ],
        projectFileIds: [
          { required: false, message: "请上传项目图纸", trigger: "change" },
        ],
      },
      projectList: [], //回显的文件列表
    };
  },
  props: {
    goods: {
      //商品列表（如果从购物车加入则不需要）
      type: Array,
      default: () => {
        return [];
      },
    },
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
    cities: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {},
    },
    formDefaultValues: Object,
  },
  components: {
    addAddress,
    uploadFile,
  },
  methods: {
    changeRemarks(v) {
      this.$emit("changeRemarks", v);
    },
    ...mapActions("purchaseMudule", [
      "selectionCreateInquiry", //生成询价单(从购物车新增)
      "inquiryCreate", //生成询价单(直接新增询价单)
      "userListByShareEnterpriseId", //
    ]),
    ...mapActions("myMessageModule", [
      "userConsigneeList", //获取收货地址列表
    ]),
    /**
     * @desc 二维数组地址格式 转 后端要的格式
     * @param array
     * @returns {*}
     */
    convertAddressFormat(array) {
      return array
        .map((item) => {
          return item.filter((v) => v !== "不限").join("-");
        })
        .join(",");
    },
    createOrder(info, callback) {
      //生成询价单
      this.$refs["form"].validate((valid) => {
        if (valid) {
          /**
           * 修改 sellerAddr 如果为不限替换为''
           * @Author zhd
           */
          this.formInline.sellerAddr[0].includes("不限") &&
            (this.formInline.sellerAddr[0][
              this.formInline.sellerAddr[0].findIndex((item) => item === "不限")
            ] = "");

            
          var data = JSON.parse(JSON.stringify(this.formInline));
          data = {
            ...data,
            ...(info || {}),
          };
          delete data.consigneePhone;
          delete data.address;
          delete data.areas;

          // console.log(this.goods.length);
          if (data.sellerUserIds.some((v) => v === false)) {
            data.isPlatform = "1"; //是否是全平台
            var text = "";
            this.cities
              .filter((v) => v.id !== "全平台")
              .map((item) => {
                //如果是全平台带上所有id
                text += text ? "," + item.id : item.id;
              });
            data.sellerUserIds = text;
          } else {
            data.isPlatform = "0";
            var text = "";
            this.cities
              .filter((v) => v.id !== "全平台")
              .map((item) => {
                //如果是全平台带上所有id
                if (
                  data.sellerUserIds.some(
                    (v) => v === item.enterpriseName || v === item.id
                  )
                ) {
                  text += text ? "," + item.id : item.id;
                }
              });
            data.sellerUserIds = text;
          }
          if (data.projectType === 0) {
            //一般项目删除这些信息
            delete data.projectFileIds; //上传文件的ids
            delete data.projectState; //项目状态
            delete data.projectProvince; //项目地址
            delete data.projectAddr; //项目详细地址
            delete data.contactName; //厂家联系人
            delete data.contactPhone; //厂家联系电话
            data.projectName = "一般项目";
          } else {
            data.projectProvince = data.projectProvince.join("-");
          }
          data.sellerAddr = this.convertAddressFormat(data.sellerAddr);
          for (var k in data) {
            if (!data[k]) {
              delete data[k];
            }
          }

          if (this.goods.length) {
            //有商品则直接加入
            var goods = [];
            this.goods.map((item) => {
              goods.push({
                skuId: item.id, //商品id
                skuQty: item.skuQty, //商品数量
              });
            });

            var obj = {
              inquiryRequestVO: {...data,inquiryCustomerId:this.formDefaultValues.value ? this.formDefaultValues.value : this.formDefaultValues.valueSupplier,remarks:this.formDefaultValues?.remarks},
              fileIds: this.files,
              itemRequestVOList: goods,
              projectFileIds: data.projectFileIds,
            };
            // console.log(obj);
            delete obj.inquiryRequestVO.projectFileIds; //删除重复的文件id
            this.inquiryCreate(obj).then((res) => {
              let { code, data: id } = res.data;
              if (code === "0") {
                ElMessage({
                  type: "success",
                  message: "添加成功",
                });
                this.clearData();
                if (callback && typeof callback === "function") {
                  callback(id);
                }
                this.$emit("success", id);
              }
            });

          } else {
            //没有商品则从购物车加入
            this.selectionCreateInquiry(data).then((res) => {
              let { code, data: id } = res.data;
              if (code === "0") {
                ElMessage({
                  type: "success",
                  message: "添加成功",
                });
                this.clearData();
                if (callback && typeof callback === "function") {
                  callback(id);
                }
                this.$emit("success", id);
              }
            });
          }
        } else {
          ElMessage({
            type: "warning",
            message: "请完善询价信息",
          });
        }
      });
    },
    addConsignee() {
      //添加收货信息
      this.addressShow = true;
    },
    changeAddress() {
      //添加地址成功后刷新列表
      this.getAddressList();
    },
    getUserList() {
      //获取询价客户列表
      this.userListByShareEnterpriseId({}).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          this.reals = data;
        }
      });
    },
    getAddressList() {
      //获取收货地址列表
      this.userConsigneeList({}).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.addressList = data;
        }
      });
    },
    getinquiryCustomerId(val) {
      // console.log(val);
      this.formInline.inquiryCustomerId = val;
      this.$emit("change", this.formInline.inquiryCustomerId);
    },
    clearData() {
      //清空数据
      this.formInline = {
        inquiryCustomerId: null, //询价人
        fixedPhone: "", //固定电话
        priceUnit: "CNY", //货币类型
        paymentType: "PAY_ON_DELIVERY", //支付方式
        projectType: 0, //项目类型
        shipId: "", //收货人id
        sellerUserIds: [], //询价设置
        isAgent: "0", //是否代理
        // shelfLife: "1", //保证期
        sellerAddr: [], //地点
        regDate: "", //注册时间
        areas: [], //所在区域
        projectProvince: [],
        projectAddr: "",
        contactName: "", //厂家联系人
        contactPhone: "", //厂家联系电话
      };
    },
  },
  computed: {
    ...mapState("purchaseMudule", [
      "paymentTypes",
      "protects",
      "moneys",
      "projectType",
      "projectState",
      "turnInquiryInfo",
    ]),
    userInfo() {
      //当前用户基本信息
      return this.$store.state.userInfo;
    },
  },

  mounted(){
    this.formInline.inquiryCustomerId=this.formDefaultValues.value ? this.formDefaultValues.value : this.formDefaultValues.valueSupplier
    this.formInline.remarks=this.formDefaultValues?.remarks
    
    console.log(this.formInline.inquiryCustomerId);

  },
  created() {

    this.cityInfo.unshift({
      label: "不限",
      name: "不限",
      value: "不限",
    });
    this.getAddressList();
    if (this.userInfo.userType === "0") {
      //如果是供应商就查询询价人列表
      this.getUserList();
    }
    this.formInline = {
      ...this.formInline,
      ...this.info,
    };
    //回显信息
    var info = JSON.parse(JSON.stringify(this.turnInquiryInfo));
    if (info) {
      info = info.inquiryRequestVO;
      //回显信息
      this.formInline.fixedPhone = info.fixedPhone;
      this.formInline.priceUnit = this.moneys.some(
        (v) => v.title === info.priceUnit
      )
        ? this.moneys.filter((v) => v.title === info.priceUnit)[0].type
        : this.formInline.priceUnit;
      this.formInline.remarks = info.remarks;
      this.formInline.paymentType = this.paymentTypes.some(
        (v) => v.title === info.paymentType
      )
        ? this.paymentTypes.filter((v) => v.title === info.paymentType)[0].type
        : this.formInline.paymentType;
      this.formInline.projectType = runNumber(info.projectType);
      this.formInline.projectName = info.projectName;
      this.formInline.projectProvince = info.projectProvince
        ? info.projectProvince.split("-")
        : [];
      this.formInline.projectState = this.projectState.some(
        (v) => v.title === info.projectState
      )
        ? this.projectState.filter((v) => v.title === info.projectState)[0].type
        : this.formInline.projectState;
      var projectFile =
          typeof info.projectFile === "string"
            ? JSON.parse(info.projectFile)
            : info.projectFile
            ? info.projectFile
            : [],
        projectList = []; //回显的文件列表
      projectFile.map((item) => {
        projectList.push({
          name: item.fileName,
          url: item.url,
          fileId: item.fileId,
        });
      });
      this.projectList = projectList;
    }
  },
  watch: {
    "formInline.shipId"(v) {
      if (v) {
        var obj = this.addressList.filter((item) => item.id === v)[0];
        this.formInline.consigneePhone = obj.consigneePhone;
        this.formInline.address = obj.address;
        this.formInline.areas = [obj.province, obj.city, obj.district || ""];
        this.$forceUpdate();
      }
    },
    "formInline.projectType"(v) {
      //项目状态改变的事件
      this.formInline.projectFileIds = [];
    },
    "formInline.sellerAddr"(a, b) {
      a = JSON.parse(JSON.stringify(a));
      b = JSON.parse(JSON.stringify(b));
      if (
        !b.some((v) => v.some((a) => a === "不限")) &&
        a.some((v) => v.some((a) => a === "不限"))
      ) {
        //取消其他选项
        var arr = a.filter((v) => v.some((a) => a === "不限"));
        this.formInline.sellerAddr = [...arr];
      } else if (b.some((v) => v.some((a) => a === "不限")) && a.length > 1) {
        var arr = a.filter((v) => v.some((a) => a !== "不限"));
        this.formInline.sellerAddr = [...arr];
      }
    },
  },
};
</script>

<style scoped lang="less">
/* 客户信息样式 */
.iu {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  font-size: 14px;

  /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }

  .iu-title {
    font-weight: bold;
    padding-bottom: 20px;
  }

  /deep/ .el-row {
    padding: 0 40px;

    .el-form-item {
      width: 100%;
    }

    .radio-tips {
      color: #d84141;
      padding: 0px 10px 0px 100px;
      position: relative;
      top: -26px;
    }
  }
}

/deep/ .el-checkbox {
  height: 36px;
}
</style>
