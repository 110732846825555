<template>
  <el-dialog
      width="600px"
      @close="close"
  >
    <div class="a">
      <div class="a-title">提示</div>
      <div class="a-content">
        <el-result
            icon="success"
            title="生成询价单成功"
            sub-title=""
        ></el-result>
        <div class="a-sub-title">链接地址：</div>
        <div class="a-m">
          <el-input v-model="getLinkUrl" disabled/>
          <el-button
              size="mini"
              type="primary"
              @click="copyLink"
              style="margin-left: 20px;">复制
          </el-button>
        </div>
      </div>
      <div class="a-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
        <el-button style="margin-left: 20px;" size="mini" type="primary" @click="close">确认</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {copyText} from '@/common/js/common.js';

export default {
  data() {
    return {}
  },
  props: ['inquiryId', 'copyContent'],
  methods: {
    close() { //关闭弹框
      this.$emit('goClose');
      this.$emit('update:modelValue', false);
    },
    copyLink() { //复制链接
      copyText(this.getLinkUrl);
    },
  },
  computed: {
    getLinkUrl() {
      return this.copyContent + " \n" + window.location.protocol + '//' + window.location.host + '/login/sharePage?id=' + this.inquiryId + '&belongType=0';
    }
  }
}
</script>

<style scoped lang="less">
.a {
  font-size: 14px;
  padding: 20px;

  .a-title {
    font-weight: bold;
    padding: 0 20px 20px 0;
  }

  .a-footer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .a-sub-title {
    padding-bottom: 10px;
  }

  .a-m {
    display: flex;
    align-items: center;
  }
}

/deep/ .el-result {
  padding-top: 0;
}
</style>
