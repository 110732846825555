<template>
  <div>
    <el-form :inline="true" :model="formInline" label-width="150px" :rules="rules" ref="form">
      <el-row>
        <el-col :span="6">
          <el-form-item label="询价设置 : 是否代理">
            <el-select v-model="formInline.isAgent">
              <el-option label="不限" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
		    <el-form-item label="保证期">
		      <el-select v-model="formInline.shelfLife">
		        <el-option
		          v-for="(item,index) in protects"
		          :label="item.title"
		          :value="item.type"
		          :key="index"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		  </el-col> -->
        <el-col :span="6">
          <el-form-item label="地点">
            <el-cascader v-model="formInline.sellerAddr" :options="cityInfo" collapse-tags clearable
              :props="{ expandTrigger: 'hover', multiple: true }" style="width: 100%;" placeholder="请选择"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌">
            <el-select v-model="formInline.isAgent"></el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">


          <el-form-item label="选择公司">
            <el-button @click="toggleRowSelection" size="mini" :disabled="dis">取消全平台后可选择
            </el-button>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="6">
		    <el-form-item label="注册时间">
		      <el-date-picker
		        v-model="formInline.regDate"
		        type="date"
		        placeholder="请选择注册时间"
		      >
		      </el-date-picker>
		    </el-form-item>
		  </el-col> -->
      </el-row>
      <el-row style="position: relative;top: -20px;">
        <el-col :span="24">
          <el-form-item label=" " prop="sellerUserIds" v-if="type == '0'">
            <el-checkbox checked v-model="formInline.sellerUserIds" style="padding-top: 7px;" @change="sel"
              :disabled="valDis">全平台
              <span style="margin-left: 15px;">选择全平台后，生成询价单后，盘雷会把你推送给全平台销售此品牌或经营此品牌的供应商。</span>
            </el-checkbox>
            <!-- <el-checkbox
              style="padding-top: 7px;"
              @change="sell"
              ></el-checkbox
            > -->
          </el-form-item>
          <el-form-item label="">
            <el-row>

              <span style="marginRight:2%;marginLeft:1%">已选公司{{ selectData.length }}条:</span>
              <el-col :span="5" v-for="o in slectFive" :key="o">
                <span style="marginRight:2%">{{ o.enterpriseName }}</span>
                <el-button type="text" @click="deleteEnter(o)">X</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog v-model="dialogTableVisible">
      <div class="a">
        <div class="a-title">选择公司</div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="10">
              <el-form-item label="是否代理">
                <el-select v-model="form.isAgent" @change="isAgentValue">
                  <el-option label="不限" value=""></el-option>
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="地点">
                <el-cascader v-model="form.sellerAddr" :options="cityInfo"
                  :props="{ expandTrigger: 'hover', multiple: true }" style="width: 100%;" placeholder="请选择"
                  @change="sellerAddrVlaue"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="品牌">
                <el-select v-model="form.enterpriseNameData"></el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-table ref="selectData" :data="cities" style="width: 100%" @selection-change="selectAll">
          <el-table-column type="selection" width="55" />
          <el-table-column property="enterpriseName" label="公司名称">
            <template v-slot="scope">
              <span>{{ scope.row.enterpriseName }}</span>
              <div style="margin-top:10px;">
                <el-tag v-if="scope.row.isAgent" size="mini" style="margin-right:10px">
                  {{ scope.row.isAgent ? '代理' : '' }}
                </el-tag>
                <el-tag v-if="scope.row.proprietaryFlag === '1'" type="success" size="mini" style="margin-right:10px">
                  {{ scope.row.proprietaryFlag === '1' ? '自营' : '' }}
                </el-tag>
                <el-tag v-if="scope.row.authFlag === '1'" type="warning" size="mini" style="margin-right:10px">{{
                  scope.row.authFlag === "1" ? "认证" : "" }}
                </el-tag>
                <el-tag v-if="scope.row.manufactorFlag === '1'" type="warning" size="mini" style="margin-right:10px">{{
                  scope.row.manufactorFlag === "1" ? "厂家" : "" }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="userName" label="负责人" />
          <el-table-column property="userMobilePhone" label="联系电话" />
        </el-table>
        <el-pagination v-model:currentPage="currentPage1" :page-size="20" :small="small" :disabled="disabled"
          :background="background" layout="total, prev, pager, next" :total="totalPage" style="textAlign:right"
          @current-change="current" />
        <div class="a-footer">
          <el-button style="margin-right: 20px;" size="mini" @click="dialogTableVisible = false">取消
          </el-button>
          <el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirmEnterpriseName">确认
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import area from "@/common/json/area.json";
import { mapActions } from "vuex";
import { handleTreeData } from "@/common/js/common.js";

export default {
  data() {
    return {
      type: "0",
      cityInfo: handleTreeData(area, "name", "name", 2),
      dialogTableVisible: false,
      formInline: {
        inquiryCustomerId: "", //询价人
        sellerUserIds: [], //被询价对象id
        remarks: "", //备注
        isAgent: "",
        sellerAddr: [["不限"]], //地点
        enterpriseNameData: [],
      },
      form: {
        isAgent: "",
        inquiryCustomerId: "", //询价人
        sellerUserIds: [], //被询价对象id
        remarks: "", //备注
        enterpriseNameData: [],
        sellerAddr: [["不限"]], //地点
      },
      rules: {
        // inquiryCustomerId: [
        //   {required: true, message: '请选择询价人', trigger: 'change'}
        // ],
        sellerUserIds: [
          { required: true, message: "请选择询价设置", trigger: "change" },
        ],
      },
      cities: [], //询价设置参数
      selectOptions: [],
      selectData: [], //公司数据
      slectFive: [],
      totalPage: 100,
      enterIds: [],
      queryData: {
        pageNo: "1",
        pageSize: "20",
      },
      sellerAddrArr: [],
      selChecked: false,
      dis: true,
      // valDis: false,
    };
  },

  methods: {
    ...mapActions("purchaseMudule", [
      "pageByCreateInquiry", //获取询价企业
    ]),
    ...mapActions("purchaseMudule", [
      "listBrandInfo", //获取询价企业对应品牌
    ]),
    current(num) {
      // console.log(num);
      this.queryData.pageNo = num;
      this.getEnterpriseList();
    },
    getEnterpriseList() {
      //获取询价企业列表
      this.pageByCreateInquiry(this.queryData).then((res) => {
        let { data, code } = res.data;
        this.totalPage = data.totalCount;
        // console.log(data);
        if (code === "0") {
          if (this.userInfo.userType === "0") {
            data.rows.map((item) => {
              item.id = item.userId;
            });
            this.cities = data.rows;
          } else {
            data.rows.map((item) => {
              item.id = item.userId;
            });
            // console.log(this.cities);
            this.cities = data.rows;
          }
        }
        let obj = [];
        for (let i = 0; i < data.rows.length; i++) {
          obj.push(data.rows[i].enterpriseId);
        }
        this.enterIds = [...obj];
        this.listBrandInfo({ enterpriseIds: this.enterIds }).then((res) => {
          // console.log(res);
          // this.form.enterpriseNameData=res.data
        });
      });
    },
    isAgentValue(val) {
      // console.log(val);
      this.queryData.isAgent = val;
      this.getEnterpriseList();
    },
    sellerAddrVlaue(val) {
      this.sellerAddrArr.push();
      let arr = [];
      let selArr = [];
      if (val) {
        for (let i = 0; i < val.length; i++) {
          let str = val[i]["0"] + "-" + val[i]["1"];
          console.log(str);
          arr.push(str);
        }
      }
      for (let i = 0; i < arr.length; i++) {
        if (selArr.indexOf(arr[i]) == -1) {
          selArr.push(arr[i]);
        }
      }
      this.sellerAddrArr = [...selArr];
      this.queryData.sellerAddrList = [...this.sellerAddrArr];
      this.getEnterpriseList();
    },
    confirm(callback) {
      this.$refs["form"].validate((v) => {
        if (v) {
          callback(this.formInline);
        }
      });
    },
    clearData() {
      this.formInline = {
        inquiryCustomerId: "", //询价人
        sellerUserIds: [], //被询价对象id
        remarks: "", //备注
      };
    },
    selectAll(selection) {
      this.selectOptions = [...selection];
      this.selectData = [...selection];
    },
    sel(val) {
      // console.log(val);
      this.selChecked = val;
      this.dis = val;
      console.log(this.formInline.sellerUserIds);
      // if (this.dis == true) {
      //   this.isPlatform = "1";
      //   this.formInline.sellerUserIds = ["全平台"];
      // } else {
      //   this.isPlatform = "0";
      //       this.formInline.sellerUserIds = this.formInline.sellerUserIds.filter(
      //     (v) => v !== "全平台"
      //   );
      // }
      this.$emit("isPlatform", val ? '1' : '0');
    },
    toggleRowSelection(row, selected) {
      this.dialogTableVisible = true;
      // console.log(row, selected);
    },
    confirmEnterpriseName() {
      // formInline.sellerUserIds
      this.dialogTableVisible = false;
      // this.selectData=[... this.selectOptions]
      let arr = [];
      let strArr = [];
      let str = "";
      if (this.selectOptions.length > 0) {
        arr = [...this.selectOptions];
      } else {
        arr.push({ ...this.row });
      }
      // console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        // console.log(arr[i]);
        strArr.push(arr[i].userId);
        str = strArr.join();
        // console.log(str);
        if (this.selectData.indexOf(arr[i]) == -1) {
          this.selectData.push(arr[i]);
        }
      }
      this.slectFive = this.selectData.slice(0, 5);
      // this.formInline.sellerUserIds = str;
      this.formInline.sellerUserIds = strArr;

      sessionStorage.setItem("selectData", JSON.stringify(this.selectData));
      sessionStorage.setItem("slectFive", JSON.stringify(this.slectFive));
      this.selectOptions = [];

      // console.log(this.selectData);
    },
    deleteEnter(val) {
      // console.log(val);
      for (let i = 0; i < this.selectData.length; i++) {
        for (let j = 0; j < this.slectFive.length; j++) {
          if (
            val.id == this.selectData[i].id &&
            val.id == this.slectFive[i].id
          ) {
            this.selectData.splice(i, 1);
            this.slectFive.splice(i, 1);
            console.log(this.slectFive.length);
          }
        }
        // console.log(this.selectData,this.slectFive);
      }
    },
  },
  computed: {
    userInfo() {
      //当前用户基本信息
      return this.$store.state.userInfo;
    },
    valDis() {

      if (this.slectFive.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.getEnterpriseList();
    this.type = JSON.parse(localStorage.getItem("userInfo")).userType;
    this.dis = this.type == '0'
    // console.log(JSON.parse(localStorage.getItem("userInfo")));
    if (this.selectData.length > 0) {
      this.selectData = JSON.parse(sessionStorage.getItem("selectData"));
      this.slectFive = JSON.parse(sessionStorage.getItem("slectFive"));
    }
  },
};
</script>

<style scoped lang="less">
.el-form-item {
  width: 100%;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.a {
  padding: 20px;

  .a-title {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .a-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}
</style>
